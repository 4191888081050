import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Record/Email/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Let InstarVision notify you with alarm emails.",
  "image": "../../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Record/Email/' locationFR='/fr/Software/Windows/InstarVision/Record/Email/' crumbLabel="Email" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeklEQVQI143HsQ6CMBhF4f9V9Pkc2DSwO9A61bAQJngVF9YONPEBjCsFSWnIMRHjaLjJl9wjLAEmvwojvPr1E9k6YwynNIM4Icp6tB3Qbka7+KPcjOrCX3kXuNwXsrPmmBzIrUekeiLlA6l7pPFI/dVsNbC/3tgV7aff3kvA79KvXEkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/e4706/Banner_en-InstarVision2-0_Record.avif 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/d1af7/Banner_en-InstarVision2-0_Record.avif 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/7f308/Banner_en-InstarVision2-0_Record.avif 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/e1c99/Banner_en-InstarVision2-0_Record.avif 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/76ea5/Banner_en-InstarVision2-0_Record.avif 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/f696c/Banner_en-InstarVision2-0_Record.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/a0b58/Banner_en-InstarVision2-0_Record.webp 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/bc10c/Banner_en-InstarVision2-0_Record.webp 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/966d8/Banner_en-InstarVision2-0_Record.webp 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/445df/Banner_en-InstarVision2-0_Record.webp 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/78de1/Banner_en-InstarVision2-0_Record.webp 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/882b9/Banner_en-InstarVision2-0_Record.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0297ec3d95ab04733625bc8830664e0d/81c8e/Banner_en-InstarVision2-0_Record.png 230w", "/en/static/0297ec3d95ab04733625bc8830664e0d/08a84/Banner_en-InstarVision2-0_Record.png 460w", "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png 920w", "/en/static/0297ec3d95ab04733625bc8830664e0d/b1001/Banner_en-InstarVision2-0_Record.png 1380w", "/en/static/0297ec3d95ab04733625bc8830664e0d/161ec/Banner_en-InstarVision2-0_Record.png 1840w", "/en/static/0297ec3d95ab04733625bc8830664e0d/29114/Banner_en-InstarVision2-0_Record.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0297ec3d95ab04733625bc8830664e0d/c0255/Banner_en-InstarVision2-0_Record.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---email-alerts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---email-alerts",
        "aria-label": "side navigation   email alerts permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - Email Alerts`}</h2>
    <p>{`Let InstarVision notify you by Email whenever a Motion Alert was triggered.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/30b04e0530fdcd0f7bb5aef1e8479996/0a47e/Image041b.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACG0lEQVQoz22TPXPTQBCG/U/pSRUKmKGDGVo6Glr+AjRUMBPCAMEJFIGCjBVbsqxv6U7WfcjOw+wZeVJQvLO7d3vvvrtazZqmYb1eU1VVsHlR4L1Ha41zjuu14iJqmd+2nP1p+HTTULUa9iOr3vO5dHyrDuisZ7bdbgNZluUUeU7TtsiZQGnNm/NbXn1MeH2W8uJ9zPN3N/xaxLB3fEi3PLooeDo/IOoMM2stRVEEdXVdk6Zp8DebDVr33HmL33ZUWYLVDVbV9FoRxwlZEtOmMWWypEpWbLVmZoyhbdugcrlchsSyLOm6LhRKNxlZnocONlkeVGulwt0qjlkJcVHQDwMiLigchgGlVID4fd8jhcQKsUBmOt2LldnfP5fcI6G02rVdUCmVRaFYUS7EAsmbfDlXSh9jwTAplCBdr8myjOXylsViQRRFLKIozFLIpwKi6kCmgjrxJ0h8JNRaBXV1XQVieSyqkyQJcZ7nwZeH04gE/1doLZ1SYf/KusH7MVzK2kiCzEj2cr/fsxtH/Dji/IgbD/D/rHWewVhmo7O4rmFoKoa6xFtz/BAyaFkfKSBLLgXuvIPdPYz37OiYXVaGh5eKZ9c9J1eKeTnAzmO9D8pGUeAcg7Xh4dt4y4OvDaeXLadXLS9/K07mLY9/dDz5qZiVg+dLYbiqTLAS76UVPwayCdIaO/ndHOe54aI0fC8N143lW2mYV4Z5afgLyteIPSqQFqUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/30b04e0530fdcd0f7bb5aef1e8479996/e4706/Image041b.avif 230w", "/en/static/30b04e0530fdcd0f7bb5aef1e8479996/d1af7/Image041b.avif 460w", "/en/static/30b04e0530fdcd0f7bb5aef1e8479996/56a33/Image041b.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/30b04e0530fdcd0f7bb5aef1e8479996/a0b58/Image041b.webp 230w", "/en/static/30b04e0530fdcd0f7bb5aef1e8479996/bc10c/Image041b.webp 460w", "/en/static/30b04e0530fdcd0f7bb5aef1e8479996/e88ff/Image041b.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/30b04e0530fdcd0f7bb5aef1e8479996/81c8e/Image041b.png 230w", "/en/static/30b04e0530fdcd0f7bb5aef1e8479996/08a84/Image041b.png 460w", "/en/static/30b04e0530fdcd0f7bb5aef1e8479996/0a47e/Image041b.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/30b04e0530fdcd0f7bb5aef1e8479996/0a47e/Image041b.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "smtp-presets",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#smtp-presets",
        "aria-label": "smtp presets permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SMTP Presets`}</h3>
    <p>{`First select your camera - or keep `}<strong parentName="p">{`All Channels`}</strong>{` selected to set all your camera´s at once - and check `}<strong parentName="p">{`Enable Email Alert`}</strong>{` to activate the email notification service. Clicking on `}<strong parentName="p">{`Default`}</strong>{` will fill out all SMTP values for you - just add your personal email address as the email receiver. The emails will be encrypted with the TLS protocol and send through an INSTAR mail server to your personal email address. Of course, you can also use one of the other presets or your personal SMTP server.`}</p>
    <h3 {...{
      "id": "email",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#email",
        "aria-label": "email permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Email`}</h3>
    <p>{`To use the INSTAR SMTP server simply select the preset and type in your personal address in the recipient field. You can let InstarVision send emails to up to three recipients - just fill out the To, CC and BCC with your three email addresses. Please always fill out a subject line and add some text to the message body, to prevent the email being classified as spam. The amount of attached images correlates directly with the time the software will need to upload your email - keep the number of images small, if you want to be notified in a timely fashion to react to the alert.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      